import React from 'react';

const BenefitsOfOverpayments = () => {
  return (
    <div>
          <div className="takeaways">
            <h2>Key Takeaways</h2>
            <ul>
              <li>Making mortgage overpayments will chip away the mortgage interest and reduces the amount you owe to the bank, and these the amount you’ll pay overall, saving you money on your mortgage</li>
              <li>
              You can reduce your loan-to-value, which means you may get access to a better mortgage rate, so can be a good idea when it comes to remortgaging{' '}
              </li>
              <li>
              Making mortgage overpayments means you’ll pay less in interest and pay off your mortgage early
              </li>
              <li>
              Making overpaying your mortgage means you'll become mortgage-free faster which can have positive effects on your mental health by reducing financial anxiety
              </li>
            </ul>
          </div>
          <div className="transcript">
            <h2>Transcript</h2>
            <p>
              What are the benefits of making overpayments?<br/><br/>
              Making overpayments can be a great way for you to pay off your mortgage faster.<br/><br/>
              By making an overpayment, you're reducing the debt owed to the bank, therefore reducing the amount that you pay overall. There are multiple benefits to making overpayments.<br/><br/>
              Number one, overpaying your mortgage could help you reduce your Loan to Value, which is the value of the mortgage measured against the value of your property.<br/><br/>
              Number two, a good reason to bring down your LTV is that lenders use this figure to decide what deals they'll offer you. The lower your LTV, the lower your interest rates you can access, so making overpayments could mean access to better mortgage deals.<br/><br/>
              Number three, save money on interest. By making overpayments, you can reduce the amount of interest you pay over the life of your mortgage, potentially saving you thousands of pounds.<br/><br/>
              Number four, the ultimate goal is to become mortgage-free, which can reduce anxiety and provide you with more control over your finances, giving you more peace of mind.<br/><br/>
            </p>
          </div>
    </div>
  );
};

export default BenefitsOfOverpayments;
